<template>
    <div class="TeamLedgerList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="stationToolFram" ref="stationToolFram">
                    <el-form-item label="条码">
                        <el-input v-model="stationToolFram.barcode" placeholder="请输入条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工器具名称">
                        <el-input v-model="stationToolFram.toolName" placeholder="请输入工器具名称查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工器具分类">
                        <el-select placeholder="请选择工器具分类名称" v-model="stationToolFram.classCode">
                            <el-option v-for="(item, index) in toolClassList" :key="index" :label="item.className"
                                :value="item.classCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="工器具编号">
                        <el-input v-model="stationToolFram.toolNum" placeholder="请输入工器具编号查询"></el-input>
                    </el-form-item> -->
                    <el-form-item label="状态">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-select placeholder="请选择工器具状态" v-model="stationToolFram.status">
                            <el-option v-for="toolStatus in toolStatusList" :key="toolStatus.pKey"
                                :label="toolStatus.pValue" :value="toolStatus.pKey"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 列表 -->
            <el-table :data="stationToolList" border  style="width: 100%; font-size: 13px;" :cell-style="bgColor" v-loading="loading"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="className" label="工器具分类" align="center"> </el-table-column>
                <el-table-column prop="toolName" label="工器具名称" align="center">
                </el-table-column>
                <el-table-column prop="toolModel" label="工器具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="voltageGrade" label="电压等级" align="center">
                </el-table-column>
                <el-table-column prop="toolNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="whCodeName" label="所在库房" align="center">
                </el-table-column>
                <el-table-column prop="location" label="存放位置" align="center">
                </el-table-column>
                <el-table-column prop="indate" label="保质期/有效期（年）" align="center">
                </el-table-column>
                <el-table-column prop="productionDate" label="出厂日期" align="center">
                </el-table-column>
                <el-table-column prop="lastTestDate" label="上次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="nextTestDate" label="下次试验日期" align="center">
                </el-table-column>
                <el-table-column prop="status_text" label="状态" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">提交试验</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="stationToolFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="stationToolFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>

import { getToolClassAll } from '@/api/toolClass.js'
import { searchStationInfo, getToolStatus, scrapStationInfo } from '@/api/stationLedger.js'
export default {
    data() {
        return {
            stationToolList: [],
            toolClassList: [],
            toolStatusList: [],
            stationToolFram: {
                barcode: null,
                toolName: null,
                classCode: null,
                toolNum: null,
                status: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false

        }
    },
    mounted() {
        this.loadToolStatus()
        this.loadToolClass()
        this.loadStationToolInfo()
    },
    methods: {

        handleSizeChange(val) {
            this.stationToolFram.pageSize = val;
            this.stationToolFram.pageNum = 1;
            this.loadStationToolInfo();
        },
        handleCurrentChange(val) {
            this.stationToolFram.pageNum = val;
            this.loadStationToolInfo();
        },

        async loadToolClass() {
            await getToolClassAll().then(res => {
                if (res.code === '000000') {
                    this.toolClassList = res.t
                }
            })
        },
        async loadToolStatus() {
            await getToolStatus().then(res => {
                if (res.code === '000000') {
                    this.toolStatusList = res.data
                }
            })
        },

        loadStationToolInfo() {
            this.loading = true
            searchStationInfo(this.stationToolFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.stationToolList = res.t.list
                        this.total = res.t.total
                        var count = 1;
                        this.stationToolList.forEach((item) => {
                            item.seq = count++
                            switch (item.status) {
                                case '00':
                                    item.status_text = '报废'
                                    break;
                                case '01':
                                    item.status_text = '正常'
                                    break;
                                case '02':
                                    item.status_text = '待试验'
                                    break;
                                case '03':
                                    item.status_text = '待试验告警'
                                    break;
                                case '04':
                                    item.status_text = '超周期不可用'
                                    break;
                                default:
                                    item.status_text = ''
                                    break;
                            }
                        })

                        console.log('this.stationToolList')
                        console.log(this.stationToolList)
                    }
                    
                }, 500);
            })
        },

        handleEdit({ id }) {
            this.$router.push({
                name: 'station-exper-test',
                params: {
                    sid: id
                }
            })
        },
        //报废
        del(id) {
            this.$confirm('是否报废？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                scrapStationInfo(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        // this.loadSelect()
                        this.loadStationToolInfo()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },

        bgColor({ row }) {
            console.log({ row })
            let cellStyle = '';
            switch (row.status) {
                case '02':
                    cellStyle = 'background-color: #FFE289;color: #000;'
                    break;
                case '03':
                    cellStyle = 'background-color: orange;color: #000;'
                    break;
                case '04':
                    cellStyle = 'background-color:rgb(237, 83, 83);color: #FFF;'
                    break;
                default:
                    cellStyle = 'background-color: #FFF;color: #000;'
                    break;

            }

            return cellStyle;

        },

        //搜索
        handleSearch() {
            this.loadStationToolInfo(this.stationToolFram);
        },
        //重置
        handleReset() {
            this.stationToolFram = {};
            this.loadStationToolInfo(this.stationToolFram);
        }

    }
}
</script>

<style lang="scss" scoped>
.TeamLedgerList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;

    }

}
</style>